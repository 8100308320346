export function getScaleFactor(ppi: number, dpr: number) {
  // scaleFactor = DEVICE_DPI / DEVICE_DPR / (326 / 2)
  // divide by 2, because @2 images are used
  var scaleFactor = ppi / dpr / (326 / 2) / 2;
  return scaleFactor;
}

export function getScaleFactorFromLocalStorage() {
  var count;
  var keys = [];
  for (count = 0; count < localStorage.length; count++) {
    keys.push(localStorage.key(count));
  }

  let result = keys.filter((name) => {
    return name && name.includes("config");
  });
  if (result.length < 1) return;

  let item = result[0];
  if (!item) return;

  let store = localStorage.getItem(item);
  if (!store) return;

  let data = JSON.parse(store);

  if (data.ppi && data.devicePixelRatio) {
    return getScaleFactor(data.ppi, data.devicePixelRatio);
  }
  return null;
}
