import clsx from "clsx";
import { useRef } from "react";
import Sound from "../../Sound";

import click from "../../../assets/sounds/klick.mp3";

// types
type Props = {
  title: string;
  onClick?: any;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  inverted?: boolean;
};

export function Button({ primary = true, disabled = false, inverted = false, onClick = () => {}, className = "", ...props }: Props) {
  const clickSound = useRef(new Sound(click));

  let styles = clsx(
    "inline-flex items-center px-3 py-3 rounded-full justify-center",
    { "cursor-not-allowed": disabled },
    { "": !disabled },
    // primary, enabled
    {
      "text-white border border-white": primary && !disabled && !inverted,
    },
    // primary, enabled, inverted colors
    {
      "text-nft-nearBlack border border-nft-nearBlack": primary && !disabled && inverted,
    },
    // primary, disabled
    {
      "text-white/50 border border-white/50": primary && disabled && !inverted,
    },
    // primary, disabled, inverted colors
    {
      "text-nft-nearBlack/50 border border-nft-nearBlack/50": primary && disabled && inverted,
    },
    // secondary, enabled
    {
      "text-white bg-white/30": !primary && !disabled && !inverted,
    },
    // secondary, enabled, inverted colors
    {
      "text-white bg-nft-nearBlack": !primary && !disabled && inverted,
    },
    // secondary, disabled
    {
      "text-white/30 bg-white/10": !primary && disabled,
    },
    className
  );

  return props.href ? (
    <a className={styles} {...props}>
      {props.title}
    </a>
  ) : (
    <button
      className={styles}
      disabled={disabled}
      onClick={() => {
        clickSound.current.play();
        onClick();
      }}
      target={props.target}
      rel={props.rel}
      {...props}
    >
      {props.title}
    </button>
  );
}

export default Button;
