import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";

import cardBg from "../../assets/img/04_verify/bg_verified.png";
import check_light from "../../assets/img/04_verify/lottie-soulmade.json";

const Success = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const imageUrl = useAppSelector((state: RootState) => state.swipe.imageUrl);
  const redirectUrl = useAppSelector((state: RootState) => state.swipe.redirectUrl);
  const invocationIsValid = useAppSelector((state: RootState) => state.app.invocationIsValid);

  useEffect(() => {
    if (invocationIsValid) {
      return;
    }
    navigate("/app", { replace: true });
  }, []);

  if (!imageUrl) return null;

  return (
    <MotionDiv className="h-full overflow-scroll">
      <div className="flex flex-col items-center justify-between h-full px-8 pt-8 pb-8 text-center text-white">
        <div className="text-2xl font-bold text-center text-soulmate-green">{t("success.title")}</div>

        <div className="w-screen mt-4 grid grid-cols-[1fr,auto,1fr]">
          <div className="col-span-3 col-start-1 row-start-1 overflow-hidden h-[40vh]">
            <img src={cardBg} className="object-cover w-full h-full" alt="" />
          </div>

          <div className="col-start-2 row-start-1 overflow-hidden h-[40vh]">
            <img src={imageUrl} className="object-contain h-full" alt="" />
          </div>
        </div>

        <Lottie animationData={check_light} loop={false} play={true} speed={1} className="h-24 mx-auto" />

        <div className="w-full px-6 space-y-4 text-center">
          <Button href={redirectUrl} title={t("success.button")} className="w-full mx-auto" target="_blank" rel="noreferrer" />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Success;
