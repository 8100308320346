import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppDispatch } from "../../state/hooks";
import { setInvocationIsValid, setNFTKey } from "../../state/slices/app";
import { useTranslation } from "react-i18next";

import bg from "../../assets/img/00_general/bg.jpg";
import logo from "../../assets/img/01_start/start_logo.png";

// TODO fix url in production
const apiUrl = "https://api-dev.prismade.net/nftplatform/validateid";

const Start = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  let navigate = useNavigate();

  let { nftKey } = useParams();

  const [startData, setStartData] = useState<any>({ title: "", category: "", imageUrl: "" });

  useEffect(() => {
    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: nftKey,
      }),
    })
      .then((response) => {
        if (!(response.status === 200)) {
          const error = (response && response.statusText) || response.status;
          return Promise.reject(error);
        }

        return response.json();
      })
      .then((data) => {
        console.log("data", data);

        setStartData({ title: data.title, category: data.category, imageUrl: data.imageUrl });

        dispatch(setInvocationIsValid());
        dispatch(setNFTKey(nftKey ?? ""));
      })
      .catch((error) => {
        console.log(error);
        console.log("redirecting");
        navigate("/app", { replace: true });
      });
  }, []);

  return (
    <MotionDiv className="h-full">
      <div
        className={clsx(
          "flex flex-col items-center  h-full pt-8 pb-8 text-white bg-center bg-cover px-8",
          startData.imageUrl ? "justify-between" : "justify-center"
        )}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <img src={logo} alt="Logo" className="h-10 drop-shadow-xl" />

        {startData.imageUrl && (
          <>
            <img src={startData.imageUrl} className="rounded-2xl h-1/2" alt="" />

            <h1 className="text-2xl font-medium leading-relaxed text-center">
              {startData.title}
              <br />
              {startData.category}
            </h1>

            <div className="w-full px-6">
              <Button
                onClick={() => {
                  navigate("/tutorial");
                }}
                title={t("start.button")}
                className="w-full"
              />
            </div>
          </>
        )}
      </div>
    </MotionDiv>
  );
};

export default Start;
