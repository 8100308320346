import { ConnectivityStatus } from "@prismadelabs/prismaid";
import { useAppSelector } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { Wifi, WifiOff } from "react-feather";

const NetworkIndicator = () => {
  const status = useAppSelector(
    (state: RootState) => state.swipe.connectivityStatus
  );

  let icon;

  if (status === ConnectivityStatus.ok) return null;

  if (status === ConnectivityStatus.slow) {
    icon = <Wifi color="orange" size={40} />;
  } else {
    // offline
    icon = <WifiOff color="red" size={40} />;
  }

  return <div className="absolute top-0 right-0 p-4 touch-none">{icon}</div>;
};

export default NetworkIndicator;
