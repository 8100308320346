import clsx from "clsx";
import { useRef } from "react";
import Sound from "../../Sound";

import icon from "../../../assets/img/03_scan/icon_help.png";
import click from "../../../assets/sounds/klick.mp3";

type Props = {
  onClick: any;
  className?: string;
};

const HelpButton = (props: Props) => {
  const clickSound = useRef(new Sound(click));

  return (
    <button
      className={clsx("p-4 flex ", props.className)}
      onClick={() => {
        props.onClick();
        clickSound.current.play();
      }}
    >
      <img src={icon} alt="help" className="h-[35px]" />
    </button>
  );
};

export default HelpButton;
