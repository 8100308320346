import clsx from "clsx";
import { useEffect, useState } from "react";

type Props = {
  src: string;
  id: string;
  alt: string;
  scaleFactor: number;
  horizontalAlign: string;
  verticalAlign: string;
  horizontalOffset?: number;
  verticalOffset?: number;
  opacity?: number;
  className?: string;
};

const LoadedImage = (props: Props) => {
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement>();
  const [horizontalPositioning, setHorizontalPositioning] = useState({});
  const [verticalPositioning, setVerticalPositioning] = useState({});

  useEffect(() => {
    let img = new Image();
    img.src = props.src;
    img.onload = () => {
      setLoadedImage(img);

      switch (props.horizontalAlign) {
        case "left":
          setHorizontalPositioning({
            left: 0 + (props.horizontalOffset || 0) * (props.scaleFactor || 1),
          });
          break;
        case "center":
          setHorizontalPositioning({
            left: `calc(50% + (${(props.horizontalOffset || 0) * (props.scaleFactor || 1)}px))`,
            transform: "translateX(-50%)",
          });
          break;
        case "right":
          setHorizontalPositioning({
            right: 0 - (props.horizontalOffset || 0) * (props.scaleFactor || 1),
          });
          break;
        default:
          console.log("no horizontalAlign defined");
          break;
      }

      switch (props.verticalAlign) {
        case "top":
          setVerticalPositioning({
            top: 0 + (props.verticalOffset || 0) * (props.scaleFactor || 1),
          });
          break;
        case "center":
          setVerticalPositioning({
            top: `calc(50% + (${(props.verticalOffset || 0) * (props.scaleFactor || 1)}px))`,
            transform: `${props.horizontalAlign === "center" ? "translateX(-50%) translateY(-50%)" : "translateY(-50%)"}`,
          });
          break;
        case "bottom":
          setVerticalPositioning({
            bottom: 0 - (props.verticalOffset || 0) * (props.scaleFactor || 1),
          });
          break;
        default:
          console.log("no horizontalAlign defined");
          break;
      }
    };
  }, []);

  if (loadedImage)
    return (
      <img
        src={loadedImage.src}
        id={props.id}
        alt={props.alt}
        className={clsx("absolute transform select-none max-w-none touch-none pointer-events-none", props.className)}
        style={{
          width: loadedImage.width * (props.scaleFactor || 1),
          ...horizontalPositioning,
          ...verticalPositioning,
          opacity: props.opacity,
        }}
      />
    );

  return null;
};

export default LoadedImage;
